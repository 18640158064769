// src/pages/Consultation.js

import React from 'react';

const Consultation = () => {
    return (
        <div>
            <h1>Оставить заявку на консультацию</h1>
            <p>Content for the Consultation page.</p>
        </div>
    );
};

export default Consultation;
