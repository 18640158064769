import React from 'react';
import './FAQ.css';

const FAQ = () => {
    const faqs = [
        {
            question: "Если тренер не сможет найти подход к моему ребенку?",
            answer: "Все наши тренеры обучены и имеют опыт работы с детьми разных диагнозов и возрастов. От нормотипичных детей до аутистов с полевым поведением."
        },
        {
            question: "Какие результаты я могу ожидать от занятий?",
            answer: "У каждого ребенка индивидуально. Все зависит от тяжести диагнозов. Методика совмещает в себе 5 направлений коррекции, поэтому у детей запускается речь, улучшается режим сна, пропадает к избирательность в еде, улучшаются моторные навыки, детки становятся усидчивыми и внимательными. А благодаря занятиям с тренером ребенок еще и социализируется — он меньше начинает пугаться незнакомых людей, адаптируется к шумным местам. Из этого вытекает то, что ребенок гораздо лучше начинает понимать обращенную к нему речь."
        },
        {
            question: "Как ваши занятия могут помочь ребенку с речевыми нарушениями?",
            answer: "Мы занимаемся нейрокоррекцией, сенсорной интеграцией и АФК на нестабильной поверхности (батут, скалодром, веревочный городок). Мы прорабатываем сенсомоторный статус ребенка, вестибулярную, тактильную, проприоцептивную систему. Это все основа для становления всех высших психических функций: речи, памяти, внимания, мышления, самоконтроля, саморегуляции."
        },
        {
            question: "Как ваши занятия могут помочь ребенку с СДВГ?",
            answer: "Гиперактивность у ребенка оттого‚ что он не чувствует свое тело в пространстве‚ свои границы (сенсорная дезинтеграция). Мы на занятиях как раз работаем над этим. Нервная система научается работать по-новому‚ правильно работать‚ правильно интегрировать‚ понимать‚ обрабатывать и реагировать на сигналы‚ поступающие от всех сенсорных систем‚ а это главное."
        },
        {
            question: "Батут безопасно?",
            answer: "Важно понимать, что мы не прыгаем на батуте, не развлекаем детей, не учим их трюкам. Мы используем батут только как нестабильную поверхность для достижения абилитационных целей, для мозжечковой стимуляции, для проработки сенсомоторного статуса, вестибулярного аппарата."
        },
        {
            question: "А нам точно подойдет ваша программа?",
            answer: "В абилитации всегда важен комплексный подход. Мы-часть этого подхода, другие его части - это врачи (невролог, психиатр и др.) и педагоги (логопед, психолог, дефектолог). Наша работа направлена на проработку сенсомоторного статуса ребенка, вестибулярной, тактильной, проприоцептивной системы. Это все ОСНОВА, БАЗА для обучения."
        },
        {
            question: "Можно ли родителю присутствовать на занятии?",
            answer: "Да, можно присутствовать на занятии, но лучше дать тренеру установить контакт с ребенком и находиться вне зоны видимости ребенка."
        }
    ];

    return (
        <div className="faq-container">
            <h1 className="faq-header">FAQ</h1>
            <div className="faq-content">
                {faqs.map((faq, index) => (
                    <div key={index} className="faq-item">
                        <div className="faq-question">{faq.question}</div>
                        <div className="faq-answer">{faq.answer}</div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default FAQ;
