import React from 'react';
import './Reviews.css';

import mikhailImage from '../../assets/reviews/mikhail.png';
import svetlanaImage from '../../assets/reviews/svetlana.png';
import tatianaImage from '../../assets/reviews/tatiana.png';
import mariyaImage from '../../assets/reviews/mariya.png';
import olyaImage from '../../assets/reviews/olya.png';
import valentinaImage from '../../assets/reviews/valentina.png';
import olegImage from '../../assets/reviews/oleg.png';
import milaImage from '../../assets/reviews/mila.png';

function Reviews() {
    const reviews = [
        {
            name: 'Михаил',
            description: 'Хочется поблагодарить нашего тренера, она замечательная! Сразу нашла подход к ребенку. Максим с удовольствием бежал на занятия. Стал более самостоятельным, собранным и спокойным. Короче, повзрослел!!! Спасибо команде "Чудо-Детки".',
            image: mikhailImage,
        },
        {
            name: 'Светлана',
            description: 'Аня не говорила ничего кроме: МАМА, ДА, НЕ. Появилось много новых звуков, слогов (открытых, закрытых), повзрослела (передать сложно, но это просто видно), перестала бегать за мной как хвостик, постоянно что-то показывает пальцем и требует сказать, что это такое, прыгает на двух ногах, на лету теперь схватывает нейрологопедические упражнения. Следующий раз планирую с двумя детьми пройти курс.',
            image: svetlanaImage,
        },
        {
            name: 'Татьяна',
            description: 'Курс прошел на одном дыхании, Оливия ходила на занятия с удовольствием. 🍀Стала более дисциплинированной, больше стала понимать сложные инструкции. Также очень важно и полезно было онлайн-сопровождение, чтобы мамочки могли разобраться во всем, что происходит с ребенком, реальная поддержка👍 очень понравилось❤️ Благодарю тренера за проделанную работу, у нас с АФК был сильный провал, на первой неделе я вообще думала что ничего не выйдет, а сейчас я плачу и не могу нарадоваться полученному результату! Спасибо!',
            image: tatianaImage,
        },
        {
            name: 'Мария',
            description: 'У нас самые замечательные впечатления о пройденном курсе! Тренер - супер профессионал! Рада, что Саша попал в такие заботливые руки! Огромное спасибо за такой важный и нужный курс для нас.',
            image: mariyaImage,
        },
        {
            name: 'Оля',
            description: 'Мы в восторге!✨ Уже вчера после первого занятия заметили, что стала София спокойнее, не было истерик, если плакала - то по делу и очень быстро успокаивалась. Спала хорошо и в дневной сон, и ночью. У нас ребенок не говорит, но как будто вчера уже стала лучше нас слушать и делать то, что просим. Очень рады, что попали на занятия! Чувствуется хорошее и заботливое отношение к ребенку на тренировках! Полностью доверяем и говорим СПАСИБО! Ждем еще больших результатов.',
            image: olyaImage,
        },
        {
            name: 'Валентина',
            description: 'У нас достижение во время курса - дочь поехала на двухколесном велосипеде, до курса не получалось, боялась упасть. С тренером проработали страх падения на батуте. В целом, тренер научил ее работать со своими страхами, и она стала меньше бояться. Стала пробовать новые вкусы и радуется, что еда вкусная, аппетит усилился. Усилилась чувствительность к звукам, запахам, ощущениям. Разделила одежду на приятную и неприятную. Зажимает уши от громких звуков, раньше этого не было. Завели дневник достижений и записываем что ей удалось сделать новое, чему научилась. Это вдохновляет и её и меня.',
            image: valentinaImage,
        },
        {
            name: 'Олег',
            description: 'Хочется ещё раз передать тренеру огромное спасибо! На второй неделе так раскачал сына, что держите его семеро😂 Матвей сам аж вспотевший выходил 😄🤭 Тренер классный, очень понравился. Ждём отличных результатов (в прошлый раз через 2 недели пошли сдвиги).',
            image: olegImage,
        },
        {
            name: 'Мила',
            description: 'Очень изменилось поведение и настроение. В субботу ездили по делам (магазины, банк и т.д.) и везде Андрюша вел себя хорошо, ждал и не капризничал, очень помогает и поддерживает онлайн-сопровождение на курсе, много полезной информации, никогда бы не подумала, что многие вещи настолько взаимосвязаны.',
            image: milaImage,
        },
    ];

    return (
        <div className="reviews-container">
            <h1 className="review-header">ВЫ О НАС</h1>
            {reviews.map((review, index) => (
                <div key={index} className="review-box">
                    <img src={review.image} alt={`${review.name}`} className="review-image" />
                    <h2 className="review-name">{review.name}</h2>
                    <p className="review-description">{review.description}</p>
                </div>
            ))}
        </div>
    );
}

export default Reviews;
